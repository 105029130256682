import React, { useState, useEffect } from "react";
import Confetti from "react-confetti";
import styles from "../styles/quiz.module.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

function QuizModal({ isOpen, onClose, cards }) {
  const [questions, setQuestions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [flipped, setFlipped] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    setQuestions(cards);
    setCurrentIndex(0);
  }, [cards]);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleFlip = () => {
    setFlipped(!flipped);
  };

  const handleCloseModal = () => {
    onClose();
    setIsCompleted(false);
    setCurrentIndex(0);
    setFlipped(false);
  };

  const handleNext = () => {
    if (currentIndex < questions.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setFlipped(false); // Reset flip state for the next question
    } else {
      setIsCompleted(true); // Show completion screen
    }
  };

  const handleBack = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      setFlipped(false); // Reset flip state for the previous question
    }
  };

  if (!isOpen) return null; // If modal is not open, return nothing

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <button className={styles.closeButton} onClick={handleCloseModal}>
          X
        </button>
        {isCompleted && (
          <div className={styles.confettiContainer}>
            <Confetti
              width={windowSize.width}
              height={windowSize.height}
              recycle={false}
            />
          </div>
        )}
        {!isCompleted ? (
          <div className={styles.cardContainer}>
            <div
              className={`${styles.card} ${flipped ? styles.cardFlipped : ""}`}
              onClick={handleFlip}
            >
              <div className={`${styles.cardSide} ${styles.cardFront}`}>
                <p>{questions[currentIndex]?.question}</p>
              </div>
              <div className={`${styles.cardSide} ${styles.cardBack}`}>
                <p>{questions[currentIndex]?.answer}</p>
              </div>
            </div>
            <div className={styles.navigation}>
              <button
                className={styles.arrowButton}
                onClick={handleBack}
                disabled={currentIndex === 0}
              >
                <ArrowBackIosIcon />
              </button>
              <span>
                {currentIndex + 1} / {questions.length}
              </span>
              <button
                className={styles.arrowButton}
                onClick={handleNext}
                disabled={!flipped}
              >
                <ArrowForwardIosIcon />
              </button>
            </div>
          </div>
        ) : (
          <div className={styles.card}>
            <h2>Parabéns!</h2>
            <p>Você descobriu todos os segredos!</p>
            <button onClick={handleCloseModal} className={styles.retryButton}>
              Fechar
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default QuizModal;
