import api from "."

export async function iaTextoResponse(body) {
    const response = await api.post("/ia/texto", body);
    return response.data;
}

export async function iaImagemResponse(body) {
    const response = await api.post("/ia/imagem", body);
    return response.data;
}

export async function iaAudioResponse(body) {
    const response = await api.post("/ia/audio", body);
    return response.data;
}

export async function iaCardReponse(body) {
    const response = await api.post("/ia/card", body);
    return response.data;
}