import React from "react";
import { Routes, Route } from "react-router-dom";
import EduAmigoExplica from "../pages/explica";
import EduAmigoFale from "../pages/fale";
import EduAmigoHome from "../pages/home";

const AppRoutes = () => (
  <Routes>
    <Route exact path="/fale" element={<EduAmigoFale />} />
    <Route exact path="/explica" element={<EduAmigoExplica />} />
    <Route exact path="" element={<EduAmigoHome />} />
  </Routes>
);

export default AppRoutes;
