import axios from 'axios';

const api = axios.create({
  baseURL: `https://api.r3mais.com.br/`,
});

function successHandler(response) {
    return response;
  }
  
async function errorHandler(error) {
return Promise.reject(error);
}

api.interceptors.response.use(
    (response) => successHandler(response),
    (error) => errorHandler(error),
  );

export default api;
